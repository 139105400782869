import { useContext, useMemo } from "react"
import AuthContext from "context/AuthContext"
import { validateWidgetId } from "helpers/generateWidgetId"
import widgetsSettings from "settings/widgetsSettings"

// import widgets
import QuickQuote from "shared/widgets/QuickQuote"
import { MarketMapWidget as MarketMap } from "shared/widgets/MarketMap"
import { NewsWidget as News } from "shared/widgets/News"
import { QuotationPanelWidget as QuotationPanel } from "shared/widgets/QuotationPanel"
import { GraphicsWidget as Graphics } from "shared/widgets/Graphics"
import { SimulatorWidget as Simulator } from "shared/widgets/Simulator"
import { TradingSummaryWidget as TradingSummary } from "shared/widgets/TradingSummary"
import { RecommendationWidget as Recommendation } from "shared/widgets/Recommendation"
import { WalletWidget as Wallet } from "shared/widgets/Wallet"
import { GenericWidget as Generic } from "shared/widgets/Generic"
import { FundsWidgets as Funds } from "shared/widgets/Funds"
import { FixedIncomeCalculatorWidget as FixedIncomeCalculator } from "shared/widgets/FixedIncomeCalculator"

const widgetsMap = {
  QuickQuote,
  News,
  MarketMap,
  Graphics,
  QuotationPanel,
  Simulator,
  TradingSummary,
  Recommendation,
  Wallet,
  Funds,
  Generic,
  FixedIncomeCalculator,
}

function useWidgets() {
  const { genericModules, userInfo: { permissions } } = useContext(AuthContext)

  const widgets = useMemo(() => {

    const permissionedWidgets = widgetsSettings
    .map(x => {
      const permission = permissions?.find(permission => permission.key === x.type)
      if (!x.defaults) x.defaults = {}
      x.defaults.permission = permission
      return { ...x, restricted: !permission }
    })

    const permissionedGenericWidget = (genericModules || []).filter(item => item.exhibition.workspace).map(item => ({
      category: item.parentMenu,
      label: item.title,
      type: "Generic",
      genericId: item.id,
      restricted: !permissions?.some(permission => +permission.key === item.id),
      defaults: {
        params: item,
        desktop: { h: 9, minH: 6 },
        tablet: { h: 9, minH: 6 },
        mobile: { h: 9, minH: 6 },
      }
    }))

    return [...permissionedWidgets, ...permissionedGenericWidget]

  }, [genericModules, permissions])

  function getWidgetsByCategory() {
    return widgets.reduce((ac, w) => {
      const found = ac.find((x) => x.category === w.category)
      if (!found) return [...ac, { category: w.category, items: [w] }]
      found.items.push(w)
      return ac
    }, [])
  }

  function getWidgetComponent(widgetId, props = {}) {
    const { type } = validateWidgetId(widgetId)
    if (!widgetsMap[type])
      throw new Error(`Invalid widget type "${type}"!`)

    const Component = widgetsMap[type]
    return <Component id={widgetId} {...props} />
  }

  return {
    widgets,
    getWidgetsByCategory,
    getWidgetComponent
  }
}

export default useWidgets
