import { usePermission } from "hooks/usePermission"
import { MdOutlineDiamond } from 'react-icons/md'
import { Link } from "react-router-dom"
import { eventAction } from "hooks/useGoogleAnalytics"

function DeniedPermissionWidget({ widgetType, children, widgetSize = 1 }) {

  const permission = usePermission(widgetType)


  if (!permission) return (
    <div className={`grid grid-flow-dense px-4 ${widgetSize === 2 ? 'grid-cols-2' : (widgetSize === 3 ? 'grid-cols-3' : '')}`}>
      <div className="rounded-xl bg-terciary p-8 text-center my-4 col-1 space-y-4 leading-6">
        <h3 className="text-xl font-bold mb-4 uppercase text-secondary">Você não tem acesso a este módulo</h3>
        <p className="mb-7 text-white">
          Tenha acesso a esse e outros módulos essenciais para melhor tomada de decisão.
        </p>
        <Link to="/planos" onClick={() => eventAction({ event: 'eventoGA', 'nome_evento': 'barreira', 'eventoGARotulo': widgetType })} className="btn btn-secondary inline-flex items-center space-x-2 text-terciary">
          <MdOutlineDiamond className="text-terciary text-lg" /> 
          <span>Fazer o Upgrade</span>
        </Link>
      </div>
    </div>
  )

  return children


}

export default DeniedPermissionWidget