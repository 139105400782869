import { ORIGINS } from "const"

const widgets = [
  // { category: "Acompanhar", label: "Podcasts", type: "Podcasts", restricted: true },
  // { category: "Acompanhar", label: "Vídeos", type: "Videos", restricted: true },
  // { category: "Investir", label: "Boleta", type: "Billet", restricted: true, },
  // { category: "Aprender", label: "Cursos", type: "Course", restricted: true },
  // { category: "Analisar", label: "Fundos", type: "Funds", restricted: true },
  {
    category: "Acompanhar",
    label: "Notícias",
    type: "News",
    defaults: {
      desktop: { h: 13, minH: 8 },
      tablet: { h: 13, minH: 8 },
      mobile: { h: 10, minH: 6 },
      params: {
        tabIndex: 0,
        filters: {
          origin: ORIGINS.reduce((ac, x) => ({ ...ac, [x.key]: true }), {}),
        },
      },
    },
  },
  {
    category: "Acompanhar",
    label: "Resumo da Bolsa",
    type: "TradingSummary",
    defaults: {
      desktop: { h: 13, minH: 8 },
      tablet: { h: 13, minH: 8 },
      mobile: { h: 10, minH: 6 },
    },
  },
  {
    category: "Acompanhar",
    label: "Painel de Cotações",
    type: "QuotationPanel",
    defaults: {
      desktop: { h: 13, minH: 10 },
      tablet: { h: 13, minH: 10 },
      mobile: { h: 11, minH: 6 },
      params: {
        display: 'table'
      }
    },
  },
  {
    category: "Acompanhar",
    label: "Mapa de Mercado",
    type: "MarketMap",
    defaults: {
      desktop: { h: 13, minH: 10 },
      tablet: { h: 13, minH: 10 },
      mobile: { h: 10, minH: 6 },
    },
  },
  {
    category: "Analisar",
    label: "Recomendações e Análises",
    type: "Recommendation",
    defaults: {
      desktop: { h: 13, minH: 6 },
      tablet: { h: 13, minH: 6 },
      mobile: { h: 10, minH: 6 },
      params: {
        filters: { company: null, customPeriod: null, period: 'W' },
      }
    },
  },
  {
    category: "Analisar",
    label: "Cotação Rápida",
    type: "QuickQuote",
    defaults: {
      desktop: { h: 13, maxW: 1, minH: 8 },
      tablet: { h: 13, maxW: 1, minH: 8 },
      mobile: { h: 13, minH: 8 },
      params: {
        symbol: {
          originId: 2,
          symbolCode: "PETR4",
        },
      },
    },
  },
  {
    category: "Analisar",
    label: "Análise Gráfica",
    type: "Graphics",
    defaults: {
      desktop: { h: 10, minH: 6 },
      tablet: { h: 10, minH: 6 },
      mobile: { h: 10, minH: 6 },
    },
  },
  {
    category: "Analisar",
    label: "Simuladores",
    type: "Simulator",
    defaults: {
      desktop: { h: 11, minH: 11, maxW: 1 },
      tablet: { h: 11, minH: 11, maxW: 1 },
      mobile: { h: 11, minH: 11 },
    },
  },
  {
    category: "Analisar",
    label: "Calculadora de Renda Fixa",
    type: "FixedIncomeCalculator",
    defaults: {
      desktop: { h: 11, minH: 8 },
      tablet: { h: 11, minH: 8 },
      mobile: { h: 11, minH: 8 },
    },
  },
  {
    category: "Investir",
    label: "Minha Carteira",
    type: "Wallet",
    defaults: {
      desktop: { h: 13, minH: 8 },
      tablet: { h: 13, minH: 8 },
      mobile: { h: 13, minH: 8 },
    },
  },
  {
    category: "Analisar",
    label: "Fundos",
    type: "Funds",
    defaults: {
      desktop: { h: 13, minH: 10 },
      tablet: { h: 13, minH: 10 },
      mobile: { h: 13, minH: 10 },
    },
  },
]

export default widgets