import React, { useState, useEffect } from "react"
import AuthorizationApi from "../api/AuthorizationApi"
import SocketBroker from "api/SocketBroker"
import GenericModulesApi from "api/GenericModulesApi"
import OneSignal from 'react-onesignal'
import { AccessInterceptorContextProvider } from 'context/AccessInterceptorContext'
import Unauthorized from "pages/Unauthorized"
import Authorizing from "pages/Authorizing"
import { PLANS } from "const"
import { eventAction } from "hooks/useGoogleAnalytics"

let client = {}

const AuthContext = React.createContext({
  isLoggedIn: false,
  isCheckingAuth: true,
  userInfo: null,
  productCode: null,
  genericModules: [],
  updateUserInfo: () => {},
  updateUserIntegration: () => { },
  onLogout: () => { },
  onLogin: () => { },
})

export function AuthContextProvider(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isCheckingAuth, setIsCheckingAuth] = useState(true)
  const [userInfo, setUserInfo] = useState(null)
  const [genericModules, setGenericModules] = useState([])

  // Login Fn disabled
  useEffect(() => {
    const CLIENT_ID = process.env.REACT_APP_GLOBO_ID
    const fetchClient = async () => {
      await window.glb.globoIdClientMap.initNewGloboIdClient({
        clientId: CLIENT_ID,
        resource: CLIENT_ID,
        url: process.env.REACT_APP_GLOBO_ID_URL,
        redirectUri: process.env.REACT_APP_GLOBO_ID_REDIRECT,
      })
    }
    fetchClient()
      .catch((error) => {
        setIsCheckingAuth(false)
        console.error(`Erro na autenticação GloboId: ${error}`)
      })
      .then((_) => {
        client = window.glb.globoIdClientMap.getGloboIdClient(CLIENT_ID)
        client.stageQueueMap.applicationUsageStageQueue = client.stageQueueMap.applicationUsageStageQueue || []
        client.stageQueueMap.applicationUsageStageQueue.push(
          async (GloboId) => {
            const isLogged = await GloboId.isLogged()
            if (isLogged) {
              client.stageQueueMap.applicationUsageStageQueue.push(
                async (GloboId) => {
                  const session = JSON.parse(
                    sessionStorage.getItem(`globoid-tokens-${CLIENT_ID}`)
                  )

                  const data = {
                    accessToken: session["access_token"],
                    idToken: session["id_token"],
                  }

                  let userData = null

                  await AuthorizationApi.authorize(data)
                    .catch((error) => {
                      setIsCheckingAuth(false)
                      console.error(`Erro: ${error}`)
                    })
                    .then((response) => {
                      window.nomePlano = PLANS.find(({code}) => code === response.productCode)?.title
                      const scriptEl = document.createElement("script")
                      scriptEl.setAttribute("type", "text/javascript")
                      scriptEl.setAttribute(
                        "src",
                        window.ambienteUtilizadoPiano === "qlt"
                          ? "https://static-stg.infoglobo.com.br/paywall/js/tiny.js"
                          : "https://static.infoglobo.com.br/paywall/js/tiny.js"
                      )
                      window.document.body.appendChild(scriptEl)
                      if (response.isAuthorized) {
                        sessionStorage.setItem("TokenApi", response.token)
                        sessionStorage.setItem(
                          "TokenApiType",
                          response.tokenType
                        )
                        userData = { ...response.userInfo, type: response.type, dateEndSignature: response.dateEndSignature, cancelDegustation: response.cancelDegustation, signature: response.productCode }
                        eventAction({ plano: window.nomePlano })
                        setIsLoggedIn(true)
                        setUserInfo(userData)
                        SocketBroker.connectSocket()
                        GenericModulesApi.fetchGenericModules().then((genericModules) => setGenericModules(genericModules))
                      } else {
                        console.error("Não Autorizado")
                      }
                    })
                  setIsCheckingAuth(false)

                  await OneSignal.init({
                    appId: process.env.REACT_APP_ONESIGNAL_ID,
                    allowLocalhostAsSecureOrigin: true
                  })
                  OneSignal.showSlidedownPrompt()
                  OneSignal.getUserId(function (userId) {
                    AuthorizationApi.subscribeOneSignal({ playerId: userId })
                      .catch((error) => console.error(`Erro: ${error}`))
                  })
                }
              )
            } else {
              client.stageQueueMap.applicationUsageStageQueue.push(
                async (GloboId) => await GloboId.login()
              )
            }
          }
        )
      })
  }, [])

  const logoutHandler = () => {
    client.stageQueueMap.applicationUsageStageQueue.push(async (GloboId) => {
      setIsLoggedIn(false)
      await GloboId.logout()
    })
  }

  const loginHandler = () => {
    client.stageQueueMap.applicationUsageStageQueue.push(async (GloboId) => {
      await GloboId.login()
    })
  }

  const updateUserInfo = (data) => {
    const userInfoUpdates = { ...(userInfo || {}), ...data }
    setUserInfo(userInfoUpdates)
  }

  const updateUserIntegration = (data) => {
    const userInfoUpdates = { ...(userInfo || {}), userIntegration: { ...(userInfo.userIntegration || {}), ...data } }
    setUserInfo(userInfoUpdates)
  }



  if (!isLoggedIn && isCheckingAuth) return <Authorizing />
  if (!isLoggedIn && !isCheckingAuth) return <Unauthorized />

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isCheckingAuth,
        userInfo,
        genericModules,
        updateUserInfo,
        updateUserIntegration,
        onLogout: logoutHandler,
        onLogin: loginHandler,
      }}
    >
      <AccessInterceptorContextProvider>
        {props.children}
      </AccessInterceptorContextProvider>
    </AuthContext.Provider>
  )
}

export default AuthContext
