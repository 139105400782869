import React, { useState } from "react"
import { useNavigate, useLocation } from 'react-router-dom'
import { BUCKET_URL } from "const"
import { eventAction } from "hooks/useGoogleAnalytics"

const BlockedDesk = BUCKET_URL + "/assets/img-bloqueio-desk.png"
const BlockedMobile = BUCKET_URL + "/assets/img-bloqueio-mobile.png"

const AccessInterceptorContext = React.createContext({
  isAccessBlocked: false,
  checkAccess: () => { },
  setIsAccessBlocked: () => { },
})

export function AccessInterceptorContextProvider(props) {
  const [isAccessBlocked, setIsAccessBlocked] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  function checkAccess(isFeatureBlock, callback) {
    if (isFeatureBlock) return setIsAccessBlocked(true)
      if (typeof callback === 'function') callback()
      }
    
    function clickAction() {
    eventAction({ event: 'eventoGA', 'nome_evento': 'barreira', 'eventoGARotulo': pathname })

    navigate('/planos')
    setIsAccessBlocked(false)
  }


  return (
    <AccessInterceptorContext.Provider value={{ isAccessBlocked, setIsAccessBlocked, checkAccess }}>
      {
        isAccessBlocked ? (
          <>
            <div onClick={() => setIsAccessBlocked(false)} className="h-screen w-screen fixed bg-black bg-opacity-25 z-[500] flex items-center justify-center">
              <img onClick={() => clickAction()} src={BlockedDesk} className="hidden md:block cursor-pointer" />
              <img onClick={() => clickAction()} src={BlockedMobile} className="block md:hidden cursor-pointer" />
            </div>
            <div className="blur-sm">
              {props.children}
            </div>
          </>
        ) : props.children
      }
    </AccessInterceptorContext.Provider>
  )
}

export default AccessInterceptorContext
