import { useEffect, useState } from "react"
import CompaniesApi from "api/CompaniesApi"
import { deburr, lowerCase } from "lodash"
import { toast } from "react-toastify"

function useCompanies({searchText, exactlyMatch}) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoading(true)

      try {
        const list = await CompaniesApi.search(searchText)
        if (exactlyMatch) {
          const exactlyCompany = list.filter(company => deburr(lowerCase(company.name)) === deburr(lowerCase(searchText)))
          setData(exactlyCompany)
          return
        }
        setError(null)
        setData(list)
      } catch (error) {
        console.error(error)
        toast.error('Falha ao buscar empresas!')
        setError(error)
        setData(null)
      } finally {
        setLoading(false)
      }
    }

    fetchCompanies()
  }, [searchText, exactlyMatch])

  return { data, loading, error }

}

export default useCompanies