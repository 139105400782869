import { flatten, isEqual } from "lodash"
import { createContext, useCallback, useEffect, useMemo, useState } from "react"
import QuoteApi from "api/QuoteApi"
import { toast } from "react-toastify"
import { usePermission, usePermissionValidation } from "hooks/usePermission"

export const RecommendationContext = createContext({
  list: [],
  selectedItem: {},
  filterValue: {},
  setSelectedItem: (value) => { },
  selectCompany: (company) => { },
  updatePeriod: (period) => { },
  loadMore: () => { }
})

export function RecommendationContextProvider({ children, widgetProps }) {
  if (Array.isArray(widgetProps?.params.filters?.customPeriod) && typeof widgetProps?.params.filters.customPeriod[0] === 'string') {
    widgetProps.params.filters.customPeriod = [new Date(widgetProps?.params.filters.customPeriod[0]), new Date(widgetProps?.params.filters.customPeriod[1])]
  }
  const [filterValue, setFilterValue] = useState(widgetProps?.params.filters)
  const [list, setList] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const itemsPerPage = useMemo(() => new Map(), [])
  const url = 'https://storage.googleapis.com/images-vo/casas-de-recomendacao/'
  const permission = usePermission('Recommendation')
  // const permission = usePermissionValidation()

  function normalizeString(str, concat) {
    const accentMap = {
      'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
      'â': 'a', 'ê': 'e', 'î': 'i', 'ô': 'o', 'û': 'u',
      'ã': 'a', 'õ': 'o', 'ç': 'c', 'à': 'a', 'è': 'e',
      'ì': 'i', 'ò': 'o', 'ù': 'u', 'ä': 'a', 'ë': 'e',
      'ï': 'i', 'ö': 'o', 'ü': 'u', 'ý': 'y', 'ñ': 'n',
    }

    return str?.toLowerCase()
      .replace(/[áéíóúâêîôûãõçàèìòùäëïöüýñ]/g, char => accentMap[char] || char)
      .replace(/\s+/g, '-').concat(concat || '')
  }

  const fetchRecommendations = useCallback(async (page = 1) => {
    try {
      const response = await QuoteApi.fetchConsensusReport({ ...filterValue, Limite: 50, Pagina: page })
      // setList(recList)
      // if (recList?.length > 0) setSelectedItem(recList[0])
      if (isEqual(response.map(x => x.idDocumento), itemsPerPage.get(page)?.map(x => x.idDocumento))) return
      itemsPerPage.set(page, response)

      const val = flatten([...itemsPerPage.values()])

      const contributorPermission = permission?.childrens.find(({ key }) => key === 'Contributors')
      const portfoliosPermission = permission
        ?.childrens.find(({ key }) => key === 'RecommendedPortfolios')
        ?.childrens.map(({ key }) => getDocumentSubtype(key))

      const normalizedList = val.map(x => {
        let permissioned = contributorPermission?.childrens?.some(({ key }) => key === normalizeString(x.contribuidor))
        if (permissioned && x.subTipoDocumento) {
          permissioned = portfoliosPermission?.some(key => key === x.subTipoDocumento)
        }

        return {
          ...x,
          icoUrl: url + normalizeString(x.contribuidor, '.svg'),
          permissioned
        }
      })
      setList(normalizedList)
      if (!selectedItem || val.every(x => x.idDocumento !== selectedItem?.idDocumento)) {
        setSelectedItem(normalizedList.find(x => x.permissioned))
      }

    } catch (error) {
      console.error(error)
      toast.error('Falha ao buscar recomendações!')
      setList(null)
    }
  }, [setList, setSelectedItem, filterValue, selectedItem])

  useEffect(() => { fetchRecommendations() }, [fetchRecommendations])

  const selectCompany = (company) => {
    updateFilters({ company })
  }

  function updateFilters(value) {
    itemsPerPage.clear()
    const filters = { ...filterValue, ...value }
    setFilterValue(filters)
    updateParams({ filters })
  }

  function updateParams(value) {
    const newParams = { ...widgetProps?.params, ...value }
    widgetProps.updateWidgetParams({ id: widgetProps.id, params: newParams })
  }

  function updatePeriod(type, value) {
    if (type === 'PERIOD')
      updateFilters({ period: value, customPeriod: null })
    else if (type === 'CUSTOM')
      updateFilters({ customPeriod: value, period: null })
  }

  function loadMore() {
    const nextPage = Math.max(...Array.from(itemsPerPage.entries()).filter(([k, v]) => v.length).map(([k]) => k)) + 1
    if (nextPage < 1) return
    fetchRecommendations(nextPage)
  }

  return (
    <RecommendationContext.Provider
      value={{
        list,
        selectedItem,
        filterValue,
        setSelectedItem,
        selectCompany,
        updatePeriod,
        loadMore
      }}
    >
      {children}
    </RecommendationContext.Provider>
  )
}


function getDocumentSubtype(key) {
  const map = {
    "alocacao-etfs---acoes": "Alocação ETFs - Ações",
    "alocacao-etfs---acoes-+-renda-fixa": "Alocação ETFs - Ações + Renda Fixa",
    "alocacao-etfs": "Alocação ETFs",
    "acoes": "Ações",
    "acoes---long-biased": "Ações - Long Biased",
    "acoes---fatores-de-risco": "Ações - Fatores de risco",
    "fii": "FII",
    "carteira-de-dividendos": "Carteira de dividendos",
    "small-caps": "Small Caps"
  }

  return map[key] || null
}

export default RecommendationContext