import { memoize } from "lodash"
import { BASE_URL, memoizeResolver, SearchParams, getApiHeaders, customFetch } from "./settings"

const URL_QUOTE = 'Quote'
const URL_SYMBOL = 'Symbols'

const Api = {

  consensusRecomendation: memoize(async function (query = {}, getRequests = ['recomendation', 'consolidated', 'consensusrecomendation']) {

    const requests = []

    if (getRequests.includes('recomendation')) {
      requests.push(
        customFetch(`${BASE_URL}/${URL_QUOTE}/recomendation?${SearchParams(query)}`, { method: 'GET', headers: getApiHeaders() })
          .then((response) => response.json())
          .then(res => res && res[0] && res[0]?.consenso)
          .catch(err => null)
      )
    }
    if (getRequests.includes('consolidated')) {
      requests.push(
        customFetch(`${BASE_URL}/${URL_QUOTE}/consensusconsolidatedrecomendation?${SearchParams(query)}`, { method: 'GET', headers: getApiHeaders() })
          .then((response) => response.status === 200 ? response.json() : null)
          .then(res => res)
          .catch(err => null)
      )
    }

    const [res1, res2] = await Promise.all(requests)

    return { ...(res1 || {}), ...(res2 || {}) }
  }, memoizeResolver),

  fetchConsensusReport: memoize(async function (params = {}) {

    const { Limite, Pagina } = params
    const searchParams = { Limite, Pagina }
    if (params.company) searchParams.Simbolos = params.company.symbols
    if (params.period) {
      const daysToSub = { 'W': 7, 'M': 30, 'Y': 365 }[params.period]
      const tempDate = new Date()
      tempDate.setDate(tempDate.getDate() - daysToSub)
      searchParams.DataFim = formatDate(new Date())
      searchParams.DataInicio = formatDate(tempDate)
    } else if (Array.isArray(params.customPeriod)) {
      searchParams.DataInicio = formatDate(params.customPeriod[0])
      searchParams.DataFim = formatDate(params.customPeriod[1])
    }

    return customFetch(`${BASE_URL}/${URL_QUOTE}/consensusreport?${SearchParams(searchParams)}`, { method: 'GET', headers: getApiHeaders() })
      .then((response) => {
        if (response.status === 200) return response.json()
        return []
      })
  }, memoizeResolver),

  autocompleteSuggestion: memoize(function (word, limit = 7) {

    if (typeof word !== 'string' || word.length < 2)
      return null
    if (!Number.isInteger(limit) || limit < 1)
      limit = 1
    const originId = [1, 2, 3, 9, 3083, 13118, 59230, 15158, 15153, 17195, 15160, 57201, 687, 1058]
    const searchParams = SearchParams({ Palavra: word, Limite: +limit, originId })

    return customFetch(`${BASE_URL}/${URL_SYMBOL}/Suggestion?${searchParams}`, { method: 'GET', headers: getApiHeaders() })
      .then((response) => response.json())
  }, memoizeResolver),

  seriesMinute: memoize(async (symbol, From, To) => {
    const searchParams = SearchParams({
      Gmt: true,
      Factor: true,
      Origin: symbol.originId,
      Symbol: symbol.name,
      From,
      To
    })
    return await customFetch(`${BASE_URL}/${URL_QUOTE}/seriesminute?${searchParams}`, { method: 'GET', headers: getApiHeaders() })
      .then((response) => {
        if (response.status === 204) return []
        if (response) return response.json()
      })
  }, memoizeResolver),

  seriesDaily: memoize(async (symbol, From, To) => {
    const searchParams = SearchParams({
      Factor: true,
      Origin: symbol.originId,
      Symbol: symbol.name,
      From,
      To
    })
    return await customFetch(`${BASE_URL}/${URL_QUOTE}/seriesdaily?${searchParams}`, { method: 'GET', headers: getApiHeaders() })
      .then((response) => {
        if (response.status === 204) return []
        if (response) return response.json()
      })
  }, memoizeResolver),

  seriesWeek: memoize(async (symbol, From, To) => {
    const searchParams = SearchParams({
      Factor: true,
      Origin: symbol.originId,
      Symbol: symbol.name,
      From,
      To
    })
    return await customFetch(`${BASE_URL}/${URL_QUOTE}/graphicsweek?${searchParams}`, { method: 'GET', headers: getApiHeaders() })
      .then((response) => {
        if (response.status === 204) return []
        if (response) return response.json()
      })
  }, memoizeResolver),

  seriesMonth: memoize(async (symbol, From, To) => {
    const searchParams = SearchParams({
      Gmt: true,
      Origin: symbol.originId,
      Symbol: symbol.name,
      From,
      To
    })
    return await customFetch(`${BASE_URL}/${URL_QUOTE}/graphicsmonth?${searchParams}`, { method: 'GET', headers: getApiHeaders() })
      .then((response) => {
        if (response.status === 204) return []
        if (response) return response.json()
      })
  }, memoizeResolver),

  seriesAnnual: memoize(async (symbol, From, To) => {
    const searchParams = SearchParams({
      Factor: true,
      Origin: symbol.originId,
      Symbol: symbol.name,
      From,
      To
    })
    return await customFetch(`${BASE_URL}/${URL_QUOTE}/graphicsannual?${searchParams}`, { method: 'GET', headers: getApiHeaders() })
      .then((response) => {
        if (response.status === 204) return []
        if (response) return response.json()
      })
  }, memoizeResolver),

  marketIndicators: async (Origin, Symbol, Fields) => {
    const searchParams = SearchParams({ Origin, Symbol, Fields })
    const response = await customFetch(`${BASE_URL}/${URL_QUOTE}/marketindicatorsfields?${searchParams}`, { method: 'GET', headers: getApiHeaders() })

    if (response.status !== 200) return null
    return response.json()
  }

}

function formatDate(d) {
  var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2)
    month = '0' + month
  if (day.length < 2)
    day = '0' + day

  return [year, month, day].join('-')
}

export default Api